<template>
  <div>
    <p>购买气血温通养生仪3.0白金套组(CN-N000110-24)</p>
    <el-table
        border
        :data="HuXi_CN_list"
        header-align="center"
        style="width: 100%">
      <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
      <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
      <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
      <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
      <el-table-column label="购买数量" prop="quantity" width="200" align="center">
        <template slot-scope="{row}">
          <el-input-number
              v-model="row.quantity"
              size="mini"
              :min="0"
              style="width:100px;"
              @change="CNN00011024_HX_Change(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-table
        border
        :data="HuYao_CN_list"
        header-align="center"
        style="width: 100%">
      <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
      <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
      <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
      <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
      <el-table-column label="购买数量" prop="quantity" width="200" align="center">
        <template slot-scope="{row}">
          <el-input-number
              v-model="row.quantity"
              size="mini"
              :min="0"
              style="width:100px;"
              @change="CNN00011024_HY_Change(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-table
        border
        :data="WeiJin_CN_list"
        header-align="center"
        style="width: 100%">
      <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
      <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
      <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
      <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
      <el-table-column label="购买数量" prop="quantity" width="200" align="center">
        <template slot-scope="{row}">
          <el-input-number
              v-model="row.quantity"
              size="mini"
              :min="0"
              style="width:100px;"
              @change="CNN00011024_WJ_Change(row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "CNN00011024",
  data(){
    return{
      tableKey:0,
      HuXi_CN_list:[
        {
          name:'健康护膝(M)',
          code:'CN-T001-01-02',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:2,
          gift:'套组内产品',
          id:-920
        },{
          name:'健康护膝(L)',
          code:'CN-T001-01-03',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:2,
          gift:'套组内产品',
          id:-921
        },{
          name:'健康护膝(XL)',
          code:'CN-T001-01-04',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:2,
          gift:'套组内产品',
          id:-922
        },{
          name:'健康护膝(特大)',
          code:'CN-T001-01-05',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:2,
          gift:'套组内产品',
          id:-923
        }
      ],
      HuYao_CN_list:[
        {
          name:'健康磁性护腰(M)',
          code:'CN-T003-01-02',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:2,
          gift:'套组内产品',
          id:-924
        },{
          name:'健康磁性护腰(L)',
          code:'CN-T003-01-03',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:2,
          gift:'套组内产品',
          id:-925
        },{
          name:'健康磁性护腰(XL)',
          code:'CN-T003-01-04',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:2,
          gift:'套组内产品',
          id:-926
        },{
          name:'健康磁性护腰(XXL)',
          code:'CN-T003-01-05',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:2,
          gift:'套组内产品',
          id:-927
        },
      ],
      WeiJin_CN_list:[
        {
          name:'能量围巾(天空蓝)',
          code:'CN-T015-01-30',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:2,
          gift:'套组内产品',
          id:-928
        },{
          name:'能量围巾(高级灰)',
          code:'CN-T015-01-40',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:2,
          gift:'套组内产品',
          id:-929
        },{
          name:'能量围巾(西柚红)',
          code:'CN-T015-01-50',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:2,
          gift:'套组内产品',
          id:-930
        },
      ],
    }
  },
  props: {
    CNN00011024num: {
      type: Number,
      default: 0
    }
  },
  watch:{
    'CNN00011024num': function (val) {
      if(val===0){
        this.HuXi_CN_list[0].quantity = 0
        this.HuXi_CN_list[1].quantity = 0
        this.HuXi_CN_list[2].quantity = 0
        this.HuXi_CN_list[3].quantity = 0
        this.HuYao_CN_list[0].quantity = 0
        this.HuYao_CN_list[1].quantity = 0
        this.HuYao_CN_list[2].quantity = 0
        this.HuYao_CN_list[3].quantity = 0
        this.WeiJin_CN_list[0].quantity = 0
        this.WeiJin_CN_list[1].quantity = 0
        this.WeiJin_CN_list[2].quantity = 0
      }
    },
  },
  methods:{
    CNN00011024_HX_Change(){
      let num = 0
      let list = []
      this.HuXi_CN_list.forEach(v=>{
        num += v.quantity
      })
      if(num !== this.CNN00011024num ){
        console.log(111)
        // this.$message.error('护膝所选尺码数量与购买套组(CN-N000039-24)数量不符')
      }else{
        this.HuXi_CN_list.forEach(v=>{
          if(v.quantity > 0){
            list.push(v)
          }
        })
      }
      this.$emit('CNN00011024_HX_Change', list)
      // console.log(this.HuXi_19508_list)
    },
    CNN00011024_HY_Change(){
      let num = 0
      let list = []
      this.HuYao_CN_list.forEach(v=>{
        num += v.quantity
      })
      if(num !== this.CNN00011024num ){
        console.log(111)
        // this.$message.error('护膝所选尺码数量与购买套组(CN-N000039-24)数量不符')
      }else{
        this.HuYao_CN_list.forEach(v=>{
          if(v.quantity > 0){
            list.push(v)
          }
        })
      }
      this.$emit('CNN00011024_HY_Change', list)
      // console.log(this.HuXi_19508_list)
    },
    CNN00011024_WJ_Change(){
      let num = 0
      let list = []
      this.WeiJin_CN_list.forEach(v=>{
        num += v.quantity
      })
      if(num !== this.CNN00011024num ){
        console.log(111)
        // this.$message.error('护膝所选尺码数量与购买套组(CN-N000039-24)数量不符')
      }else{
        this.WeiJin_CN_list.forEach(v=>{
          if(v.quantity > 0){
            list.push(v)
          }
        })
      }
      this.$emit('CNN00011024_WJ_Change', list)
      // console.log(this.HuXi_19508_list)
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <p style="font-size: 20px">购买气血温通养生仪3.0套组(CN-N000109-24)请选择赠品</p>
    <el-radio v-model="id" :label="-22" style="margin-bottom: 10px;"  @input="get_CNN00010924_Change" >
      <span >赠品1:健康磁性多功能床垫（小）(CN-T008-01-00)*1</span>
    </el-radio>
    <br>
    <el-radio v-model="id" :label="-21" style="margin-bottom: 10px;" @input="get_CNN00010924_Change">
      <span>赠品2:健康磁性多功能床垫(CN-T009-01-00)*1</span>
    </el-radio>
  </div>
</template>

<script>
export default {
  name: "CNM0060120",
  data(){
    return{
      id:''
    }
  },
  props: {
    CNN00010924num: {
      type: Number,
      default: 0
    }
  },
  watch:{
    CNN00010924num: {
      handler: function (val) {
        if(val === 0){
          this.id = ''
          this.getKFYChange()
        }
      },
      immediate: true
    }
  },
  methods:{
    get_CNN00010924_Change(){
      this.$emit('get_CNN00010924_Change', this.id)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-radio__label{
  font-size: 18px;

}
</style>
